<template>
  <div class="wrap">
    <el-card style="overflow: scroll;">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="模板名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="发货方式" prop="sendType">
          <el-radio-group v-model="ruleForm.sendType" size="small">
            <el-radio-button :label="2">店铺发货</el-radio-button>
            <el-radio-button :label="1">商城发货</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="参与店铺" prop="shopId">
          <el-select :filterable="true" :clearable="true" v-model="ruleForm.shopId" @change="handleShopChange" :disabled="userInfo.type == 3" placeholder="请选择参与店铺">
            <el-option
              v-for="item in allChildList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <!-- <el-cascader
            v-model="ruleForm.shopId"
            :options="selectOption"
            :filterable="true"
            :clearable="true"
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'childList',
            }"
            @change="handleShopChange"
          ></el-cascader> -->
          <!-- <el-checkbox v-model="ruleForm.isAllShop">全部店铺</el-checkbox>
          <div>
            <span>共选择了{{ ruleForm.isAllShop ? allChildList.length : shopDialogForm.checked.length }}个店铺</span>
            <el-button :disabled="ruleForm.isAllShop" style="margin-left: 10px;" type="primary" @click="handleSelectShop">选择店铺</el-button>
          </div> -->
        </el-form-item>
        <el-form-item v-if="ruleForm.sendType == 2" label="快递公司" prop="logisticsCompany">
          <el-select
            v-model="ruleForm.logisticsCompany"
            placeholder="请选择快递公司"
          >
            <el-option
              v-for="(item, index) in logisticsCompanyList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否包邮" prop="shippingIncluded">
          <el-radio-group v-model="ruleForm.shippingIncluded" size="small">
            <el-radio-button :label="true">包邮</el-radio-button>
            <el-radio-button :label="false">不包邮</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.shippingIncluded" label="包邮金额" prop="shippingIncludedAmount">
          <el-input-number v-model="ruleForm.shippingIncludedAmount" :min="0" :max="9999999.99" :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="默认快递金额" prop="defaultAmount">
          <el-input-number v-model="ruleForm.defaultAmount" :min="0" :max="9999999.99" :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="计费方式" prop="billingType">
          <el-radio-group v-model="ruleForm.billingType" size="small">
            <el-radio-button :label="1">按重量计费</el-radio-button>
            <el-radio-button :label="2">按件计费</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="计费详情">
          <div>
            <el-button style="background-color: #409EFF; color: #ffffff;" @click="handleAddItem">
              添加配送区域
            </el-button>
            <el-table :data="tableData" style="width: 100%;">
              <el-table-column prop="logisticsArea" label="配送区域">
                <template slot-scope="scope">
                  <template v-if="scope.row.national">
                    全国
                  </template>
                  <template v-else>
                    <span
                      v-for="(item, index) in scope.row.logisticsArea"
                      :key="index"
                    >
                      {{ item.label }}|
                    </span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="firstWeight"
                label="首重/件"
              ></el-table-column>
              <el-table-column
                prop="firstAmount"
                label="运费（元）"
              ></el-table-column>
              <el-table-column
                prop="nextWeight"
                label="续重/件"
              ></el-table-column>
              <el-table-column
                prop="nextAmount"
                label="续费（元）"
              ></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!--   @click="handleDeleteBilling(scope.$index, scope.row)"  -->
                  <el-popconfirm
                    title="您确定要删除该条数据吗?"
                    @confirm="handleDeleteBilling(scope.$index, scope.row)"
                  >
                    <el-button slot="reference"size="mini" type="danger">删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button type="primary" @click="submitForm('ruleForm')">
          提交
        </el-button>
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </el-card>
    <el-dialog title="添加" @close="handleDialogClose" :visible.sync="dialogVisible" width="600px">
      <el-form
        :model="dialogForm"
        :rules="dialogRules"
        
        ref="dialogForm"
        label-width="100px"
      >
        <el-form-item label="是否全国配送">
          <el-switch v-model="dialogForm.national"></el-switch>
        </el-form-item>
        <el-form-item label="可配送区域">
          <el-select
            style="width: 400px;"
            size="medium"
            multiple
            :disabled="dialogForm.national"
            v-model="dialogForm.logisticsArea"
            :value-key="'value'"
          >
            <el-option
              v-for="item in this.provinceAndCityData"
              :key="item.value"
              :label="item.label"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="首重（kg）" prop="firstWeight">
          <el-input-number
            style="width: 200px;"
            v-model="dialogForm.firstWeight"
            :min="0"
            :max="9999999.99"
            :precision="2"
            placeholder=""
          ></el-input-number>
        </el-form-item>
        <el-form-item label="邮费（元）" prop="firstAmount">
          <el-input-number
            style="width: 200px;"
            v-model="dialogForm.firstAmount"
            :min="0"
            :max="9999999.99"
            :precision="2"
            placeholder=""
          ></el-input-number>
        </el-form-item>
        <el-form-item label="续重（kg）" prop="nextWeight">
          <el-input-number
            style="width: 200px;"
            v-model="dialogForm.nextWeight"
            :min="0"
            :max="9999999.99"
            :precision="2"
            placeholder=""
          ></el-input-number>
        </el-form-item>
        <el-form-item label="续重（元）" prop="nextAmount">
          <el-input-number
            style="width: 200px;"
            v-model="dialogForm.nextAmount"
            :min="0"
            :max="9999999.99"
            :precision="2"
            placeholder=""
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleDialogCancel">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择店铺 -->
    <el-dialog
      title="选择店铺"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
      custom-class="mainShopDialog"
      @close="handleClose"
    >
      <p>选择楼层</p>
      <el-input
        v-model="filterText"
        placeholder="请输入店铺名称"
        size="medium"
        style="width: 260px; margin-top: 10px;"
        @change="filterShop"
      ></el-input>
      <el-tabs class="floor mainShopFloor" v-model="shopDialogForm.activeName">
        <el-tab-pane
          v-for="(item, index) in childList"
          :key="index"
          :label="item.name"
          :name="item.name"
        >
          <div class="floorShop">
            <el-checkbox-group
              v-model="shopDialogForm.checked"
              @change="handleChange"
            >
              <el-checkbox
                :label="shop.shopName + 'id:' + shop.id"
                v-for="(shop, index) in item.childList"
                :key="index"
              >
                {{ shop.name }}
              </el-checkbox>
              <!-- <el-checkbox v-for="item in 10" :key="item" @change="ladderChange" ></el-checkbox> -->
            </el-checkbox-group>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="innerDialogCancel">取 消</el-button>
        <el-button type="primary" @click="innerDialogConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { provinceAndCityData, regionData } from 'element-china-area-data'
import Place from './components/place'
export default {
  name: 'ExpressTemplateEdit',
  components: { Place },
  data() {
    return {
      provinceAndCityData,
      regionData,
      ruleForm: {
        name: '',
        sendType: 2,
        shippingIncluded: true,
        billingType: 1,
        logisticsCompany: undefined,
        shippingIncludedAmount: 0,
        shopId: undefined,
        defaultAmount: '0'
      },
      dialogForm: {
        logisticsArea: [],
        firstAmount: 0,
        firstWeight: 0,
        nextAmount: 0,
        nextWeight: 0,
        national: false,
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        sendType: [
          { required: true, message: '请选择发货方式', trigger: 'change' },
        ],
        shippingIncluded: [
          { required: true, message: '请选择是否包邮', trigger: 'change' },
        ],
        defaultAmount: [
          { required: true, message: '请输入金额', trigger: 'blur', type: 'number' }
        ],
        billingType: [
          { required: true, message: '请选择计费方式', trigger: 'change' },
        ],
        logisticsCompany: [
          { required: true, message: '请选择快递公司', trigger: 'change' },
        ],
        shopId: [
          { required: true, message: '请选择参与店铺', trigger: 'change' },
        ],
      },
      dialogRules: {
        name: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        firstWeight: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        firstAmount: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        nextWeight: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        nextAmount: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      /* 计费详情 */
      tableData: [],
      dialogVisible: false,
      /* 选择店铺 */
      childList: [],
      allChildList: [],
      allShopList: [],
      orgChecked: [], // 未确认添加之前的id
      selectOption: [],
      shopDialogForm: {
        activeName: '',
        checked: [],
        action: '',
      },
      filterText: '',
      innerVisible: false,
      logisticsCompanyList: [], // 快递公司列表
      userInfo: {}
    }
  },
  created() {
    if (this.$route.params.id) {
      this.handleInit(this.$route.params.id)
    }
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (this.userInfo.type == 3) {
      this.getItemList(this.userInfo.shopId)
    }
    console.log('this.userInfo', this.userInfo);
    // this.handleGetLogisticsBillingList()
    this.getShopList()
  },
  methods: {
    /* 选择店铺 */
    // 选择了店铺
    handleShopChange(v) {
      this.logisticsCompanyList = []
      this.ruleForm.logisticsCompany = undefined
      this.getItemList(v)
    },
    // 快递公司列表
    async getItemList(shopId) {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        shopId,
      }
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.getLogisticsCompanyList(params)
      if (code === 200) {
        this.logisticsCompanyList = data.list
      }
    },
    // 打开选择店铺弹窗
    handleSelectShop() {
      this.filterText = ''
      this.childList = JSON.parse(JSON.stringify(this.allShopList))
      this.orgChecked = this.shopDialogForm.checked
      this.innerVisible = true
    },
    // 选择店铺
    handleChange(v) {
      if (this.shopDialogForm.action == 'edit') {
        this.shopDialogForm.checked.length > 1 &&
          this.shopDialogForm.checked.shift()
      }
    },
    // 右上角关闭弹窗
    handleClose() {
      this.shopDialogForm.checked = this.orgChecked
      this.innerVisible = false
    },
    // 取消添加
    innerDialogCancel() {
      this.shopDialogForm.checked = this.orgChecked
      this.innerVisible = false
    },
    // 确认添加
    innerDialogConfirm() {
      this.shopDialogForm.shopName = ''
      this.orgChecked = this.shopDialogForm.checked
      this.innerVisible = false
    },
    // 删除计费列表的某一项
    async handleDeleteBilling (index, row) {
      if (this.$route.params.id) {
        const { code } = await this.$api.shopManageApi.deleteLogisticsBilling({ id: row.id })
        if (code == 200) {
          this.$message.success('操作成功')
          this.handleGetLogisticsBillingList(this.$route.params.id)
        }
      } else {
        this.tableData.splice(index, 1)
      }
    },
    // 店铺列表
    getShopList() {
      const { shopId, shopName, type} = this.userInfo
      if (type == 2) {
        this.$api.shopapi.getShopList().then(({ data, code }) => {
          if (code == 200) {
            let childList = JSON.parse(JSON.stringify(data.childList))
            let allChildList = []
            data.childList.forEach((child) => {
              child.childList.forEach((shop) => {
                allChildList.push(shop)
              })
            })
            this.allChildList = allChildList
            // console.log('allChildList', allChildList)
            let allShop = {
              name: '全部',
              childList: allChildList,
            }
            childList.unshift(allShop)
            this.allShopList = JSON.parse(JSON.stringify(childList))
            this.childList = JSON.parse(JSON.stringify(childList))
            this.shopDialogForm.activeName =
              this.allShopList.length > 0 ? this.allShopList[0].name : null
            const child = [
              {
                id: data.id,
                name: data.name,
                childList: data.childList,
              },
            ]
            this.selectOption = child
          }
        })
      } else if (type == 3) {
        this.allChildList = [
          {
            name: shopName,
            id: shopId
          }
        ]
        this.ruleForm.shopId = shopId
      }
    },
    // 过滤店铺
    filterShop(val) {
      if (val) {
        this.childList.forEach((item) => {
          item.childList = item.childList.filter((shop) =>
            shop.name.includes(val),
          )
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },
    /* 计费详情 */
    handleAddItem() {
      this.dialogVisible = true
    },
    async handleInit (id) {
      // const billingListResult = await this.$api.shopManageApi.getLogisticsBillingList({
      //   logisticsTemplateId: id,
      // })
      // if (billingListResult.code == 200) {
      //   this.tableData = billingListResult.data
      // }
      this.handleGetLogisticsBillingList(id)
      const templateDetailResult  = await this.$api.shopManageApi.getLogisticsTemplateDetail({id
      })
      if (templateDetailResult.code == 200) {
        const {name, sendType, shopId, shippingIncluded, billingType, logisticsCompany,shippingIncludedAmount, defaultAmount } = templateDetailResult.data
        this.ruleForm = {
          name,
          sendType,
          shippingIncluded,
          billingType,
          logisticsCompany,
          shippingIncludedAmount,
          shopId,
          defaultAmount
        }
        this.ruleForm.shopId = templateDetailResult.data.shopId
        this.getItemList(templateDetailResult.data.shopId)
      }

    },
    // 计费规则列表
    async handleGetLogisticsBillingList(id) {
      const {
        code,
        data,
        msg,
      } = await this.$api.shopManageApi.getLogisticsBillingList({
        logisticsTemplateId: id,
      })
      if (code == 200) {
        this.tableData = data
      }
    },

    async handleDialogConfirm() {
      // provinceName   provinceCode
      // this.dialogForm.logisticsArea = this.dialogForm.logisticsArea.map(item => {
      //   return {
      //     provinceName: item.label,
      //     provinceCode: item.value
      //   }
      // })
      if (this.$route.params.id) {
        let arr = []
        arr.push(Object.assign({logisticsTemplateId: this.$route.params.id}, this.dialogForm))
        console.log('arr', arr);
        await this.$api.shopManageApi.batchAddLogisticsBilling(arr)
        this.$refs.dialogForm.resetFields()
        this.dialogForm.logisticsArea = []
        this.dialogVisible = false
        this.handleGetLogisticsBillingList(this.$route.params.id)
      } else {
        this.tableData.push(Object.assign({}, this.dialogForm))
        this.$refs.dialogForm.resetFields()
        this.dialogForm.logisticsArea = []
        this.dialogVisible = false
      }
    },
    handleDialogCancel () {
      this.$refs.dialogForm.resetFields()
      this.dialogForm.logisticsArea = []
      this.dialogVisible = false
    },
    handleDialogClose () {
      this.$refs.dialogForm.resetFields()
      this.dialogForm.logisticsArea = []
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {
            shippingIncluded,
            sendType,
            name,
            logisticsCompany,
            billingType,
            shopId,
            shippingIncludedAmount,
            defaultAmount
          } = this.ruleForm
          const postData = {
            id: this.$route.params.id ? this.$route.params.id : undefined,
            shippingIncluded,
            sendType,
            name,
            logisticsCompany,
            billingType,
            shippingIncludedAmount,
            defaultAmount,
            shopIds: [shopId] 
          }
          if (!this.$route.params.id) {
            const addTemplateResult = await this.$api.shopManageApi.addLogisticsTemplate(postData)
            if (addTemplateResult.code == 200) {
              const templateId = addTemplateResult.data[0]
              this.tableData = this.tableData.map(item => {
                item.logisticsTemplateId = templateId
                return item
              })
              const addBillingResult = await this.$api.shopManageApi.batchAddLogisticsBilling(this.tableData)
              if (addBillingResult.code == 200) {
                this.$message.success('添加成功')
                this.$router.back()
              }
            } 
          } else {
            const addTemplateResult = await this.$api.shopManageApi.updateLogisticsTemplate(postData)
            if (addTemplateResult.code == 200) {
              // this.tableData = this.tableData.map(item => {
              //   item.logisticsTemplateId = this.$route.params.id
              //   return item
              // })
              this.$message.success('修改成功')
              this.$router.back()
              // const addBillingResult = await this.$api.shopManageApi.batchAddLogisticsBilling(this.tableData)
              // if (addBillingResult.code == 200) {
              //   this.$message.success('修改成功')
              //   this.$router.back()
              // }
            } 
          }
         } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  .floor {
    margin-top: 10px;
    .floorShop {
      width: 100%;
      min-height: 300px;
      height: 300px;
      border: 1px solid #ccc;
      padding: 20px 10px;
      box-sizing: border-box;
      font-size: 12px;
      overflow: auto;
      .el-checkbox {
        margin: 20px 0 0 15px;
        margin-top: 20px;
        width: 43%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .el-checkbox:nth-child(-n + 2) {
        margin-top: 0;
      }
    }
  }
  .footer {
    width: 100%;
    text-align: right;
  }
}
</style>
